/**
 Feuille de style pour surcharger le front office
 */
body {
  background-color: #fff;
}

.header, .header-appli, .footer {
  background-color: #005e89 !important;
}

.header-appli {
  background: url("../images/bandeau_mobile_transparence.png") no-repeat;
}

@media (min-width: 768px) {
  // tablette
  .header-appli {
    background: url("../images/bandeau_tablette_transparence.png") no-repeat;
  }
}

@media (min-width: 1199px) {
  // pc
  .header-appli {
    background: url("../images/bandeau_pc_transparence.png") no-repeat;
  }
}

.footer .logo {
  width: 130px;
  height: auto;
}

.font-sans-serif {
  font-family: sans-serif;
}

.btn {
  border-radius: 4px;
}

.border-left-5 {
  border-left-width: 5px !important;
}

.card-footer {
  border-top: none;
  background-color: transparent;
}

.card-header {
  background-color: transparent;
  border-bottom: transparent;
}

.card {
  border: 1px solid transparent;
  background-color: white;
  box-shadow: 0 0 .875rem 0 rgba(33, 37, 41, .05);
}

body {
  background-color: #f5f7fb;
}

@each $name, $color in (default, #777), (primary, #428bca), (success, #5cb85c), (danger, #d9534f), (warning, #f0ad4e), (info, #17a2b8), (bdc, #29527a) {
  .callout-#{$name} {
    border-left-color: $color !important;

    h4, h5 {
      color: $color;
    }
  }
}

.text-dark-blue {
  color: #005e89;
}

[readonly=readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.process_construction {
  i {
    color: #005e89;
  }
  .card {
    border-color: #005e89 !important;
    border-width: 4px !important;
  }
  .row {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .etape {
    color: #005e89;
    font-size: 1.2em;
    border-color: #005e89 !important;
    padding: 1px 10px;
  }
}